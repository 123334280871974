<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        渠道配置
      </template>
      <template #input>
        <a-input v-model="channelName" placeholder="请输入渠道方名称" />
        <a-button
          type="primary"
          class="btn"
          @click='onSearch()'
        >搜索</a-button>
        <a-button
          class="btn"
          @click='OpenChannelModal(1)'
        >新建</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index)=>item.id"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="effectiveTime" slot-scope="text">
          <span>{{ text }}小时</span>
        </template>
        
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="OpenChannelProductModal(row)">商品配置</a>
            <span> | </span>
            <a @click="OpenChannelModal(2,row)">渠道维护</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      :title="titleName"
      :visible="addChannerShow"
      :confirm-loading="channelLoading"
      @ok="onAddChannelHandle"
      @cancel="CloseChannelModal"
      :width=440
    >
      <div class="content">
        <div class="list">
          <span>渠道名称：</span>
          <a-input
            class="input"
            :disabled="isDisable"
            v-model="channelForm.channelName"
            placeholder="请输入渠道名称"
          />
        </div>
        <div class="list">
          <span>用户名：</span>
          <a-input
            class="input"
            :disabled="isDisable"
            v-model="channelForm.username"
            placeholder="请输入英文用户名"
          />
        </div>
        <div class="list">
          <span>密码：</span>
          <a-input
            class="input"
            v-model="channelForm.password"
            placeholder="请输入密码"
          />
        </div>
        <div class="list">
          <span>时效：</span>
          <a-input-number class="input" v-model="channelForm.effectiveTime" :min="0" placeholder="用于渠道链接购买的生效时间段"></a-input-number>
          <p style="margin-left:5px">小时</p>
        </div>
      </div>
    </a-modal>
    <!-- 商品配置 -->
    <a-modal
      title="商品配置"
      :visible="productShow"
      @cancel="productShow = false"
      :width=1200
    >
      <div class="content">
        <div class="add-btn">
          <p>渠道方：{{channelTitle}}</p>
          <a-button style="add" type="primary" @click="productAddVisible = true">新建</a-button>
        </div>
        <a-table
          :rowKey="(item,index)=>index"
          :loading="loading"
          :columns="ProductColumns"
          :data-source="productData"
          @change="onProductPage"
          :pagination="{
            total:productTotal,
            current:productNum,  
            defaultPageSize:productSize, 
            showSizeChanger: true,
            showTotal: function(total, range){
            return `共${total}条`}}"
          >
          <!-- 索引 -->
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ (productNum - 1) * productSize + i + 1 }}
            </div>
          </template>
          <!-- 标记方式 -->
          <template slot="linkType" slot-scope="text">
            <span>{{text == 1 ? '课程提供方' : text == 2 ? '链接购课' : text == 3 ? '优惠券购课' : ''}}</span>
          </template>
          
          <!-- 链接 -->
          <template slot="channelLinkURL" slot-scope="text">
            <template v-if="text">
              <span>{{text}}</span>  <a @click="copyPost(text)">复制</a>
            </template>
            <template v-else>
              <span>-</span>
            </template>
          </template>
          <!-- 操作 -->
          <template slot="operation" slot-scope="item, row">
            <div class="btn_router_link">
              <a v-show="row.linkType == 2" @click="copyQRCode(row)">下载二维码</a>
              <span v-show="row.linkType == 2"> | </span>
              <a-popconfirm
                  title="是否确认删除该关联商品?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="onProductDel(row)"
                >
                  <a href="#">删除</a>
              </a-popconfirm>
            </div>
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <a-button @click="productShow = false">关闭</a-button>
      </template>
    </a-modal>
    <!-- 新建关联商品 -->
    <a-modal
      title="关联商品"
      :visible="productAddVisible"
      :confirm-loading="loading"
      @cancel="productAddVisible = false"
      @ok="onChannelProductAdd"
      :width=500
    >
      <div class="content">
        <div class="list">
          <span>标记方式：</span>
          <a-radio-group v-model="radioType" class="input" name="radioGroup">
            <a-radio :value="2">链接购课</a-radio>
            <!-- <a-radio :value="1">课程提供方</a-radio> -->
            <a-radio :value="3">优惠券购课</a-radio>
          </a-radio-group>
        </div>
        <div class="list" v-if="radioType == 1 || radioType == 2">
          <span>关联商品：</span>
          <a-select
            placeholder="请选择关联商品"
            style="width: 300px"
            :filter-option="filterOption"
            v-model="productId"
            showSearch
            allowClear
          >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="item in courseData" :key="item.productId"
            :value="item.productId">{{item.productName}}</a-select-option>
          </a-select>
        </div>
        <div class="list" v-if="radioType == 3">
          <span>关联优惠券：</span>
          <a-select
            style="width: 300px"
            placeholder="请选择关联优惠券"
            :filter-option="filterOption"
            v-model="productId"
            showSearch
            allowClear
          >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="item in couponData" :key="item.couponProductId + 'key'"
            :value="item.couponProductId">{{item.couponName}}-{{item.name}}</a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "渠道方",
    align: "center",
    dataIndex: "channelName",
  },
  {
    title: "用户名",
    align: "center",
    dataIndex: "username",
  },
  {
    title: "渠道code",
    align: "center",
    dataIndex: "channelCode",
  },
  {
    title: "时效",
    align: "center",
    dataIndex: "effectiveTime",
    scopedSlots: { customRender: "effectiveTime" },
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    width: 200,
    scopedSlots: { customRender: "operation" },
  },
];
const ProductColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "关联商品",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "标记方式",
    align: "center",
    width: "120px",
    dataIndex: "linkType",
    scopedSlots: { customRender: "linkType" },
  },
  {
    title: "渠道链接",
    align: "center",
    dataIndex: "channelLinkURL",
    scopedSlots: { customRender: "channelLinkURL" },
  },
  {
    title: "操作",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "operation" },
  },
];
const key = 'updatable';
import { downloadBase64Image } from '@/unit/fun.js'
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      titleName:'',
      channelAddType: 0, // 添加方式  1.新增  2.编辑
      channelId: 0, // 渠道方id
      radioType: 2, // 商品标记类型
      channelTitle:'', // 渠道方名称
      columns, // 表头
      ProductColumns,// 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      channelName: null, // 渠道名称
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      productNum:1,
      productSize:10,
      productTotal:0,
      addChannerShow: false, // 渠道配置弹窗显隐
      channelLoading: false, // 添加渠道加载状态
      productShow:false,
      productData:[],
      productAddVisible:false,
      isDisable:false,
      channelForm:{
        channelName:'',
        username:'',
        password:'',
        effectiveTime:'',
        channelType: 1,
      },

      groupedProductIds:[],
      courseData:[], // 关联商品课程列表
      couponData:[], // 关联优惠券列表
      productId: undefined,
    };
  },
  // 事件处理器
  methods: {
    // 复制链接
    copyPost(text) {
      var input = document.createElement("input"); // 直接构建input
      input.value = text; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    // 分页
    onProductPage(e) {
      this.productNum = e.current;
      this.productSize = e.pageSize;
    },
    onSearch() {
      this.pageNumber = 1
      this.getManageList();
    },
    
    // 获取渠道列表
    getManageList() {
      this.loading = true
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/login/list',
        params: {
          channelName: this.channelName,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    // 新建/编辑 渠道
    onAddChannelHandle(){
      let form = {}
      if(this.channelAddType == 1){ // 渠道新增
        if(!this.channelForm.channelName){
          this.$message.warning('请输入渠道名称');
          return
        } else if (!this.channelForm.username){
          this.$message.warning('请输入用户名');
          return
        } else if (!this.channelForm.password){
          this.$message.warning('请输入密码');
          return
        } else if (!this.channelForm.effectiveTime){
          this.$message.warning('请输入时效时间');
          return
        }
        form = this.channelForm
      } else if (this.channelAddType == 2){ // 渠道编辑
        if (!this.channelForm.password){
          this.$message.warning('请输入密码');
          return
        } else if (!this.channelForm.effectiveTime){
          this.$message.warning('请输入时效时间');
          return
        }
        form = {
          id: this.channelId,
          password: this.channelForm.password,
          effectiveTime: this.channelForm.effectiveTime,
        }
      }
      this.channelLoading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/login/edit',
        params: form
      }).then((res) => {
        this.channelLoading = false
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.$message.success("操作成功");
          this.addChannerShow = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 打开渠道配置弹窗
    OpenChannelModal(type,e){
      this.titleName = (type == 1 ? '新建渠道方' : '编辑渠道方')
      this.channelAddType = type
      if(type == 2){
        this.isDisable = true
        this.channelId = e.id
        this.channelForm = {
          channelName: e.channelName,
          username: e.username,
          password: e.password,
          effectiveTime: e.effectiveTime,
          channelType:1,
        }
      } else {
        this.isDisable = false
        this.channelForm = {
          channelName:'',
          username:'',
          password:'',
          effectiveTime:'',
          channelType:1,
        }
      }
      
      this.addChannerShow = true
    },
    // 关闭渠道配置弹窗
    CloseChannelModal(){
      this.addChannerShow = false
    },

    // 打开渠道关联商品
    OpenChannelProductModal(e){
      this.loading = true
      if(e){
        this.channelId = e.id
      }
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/login/link/product',
        params: {
          id: this.channelId,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          res.data.forEach(element => {
            if(element.linkType == '3'){
              element.productId = element.linkId
            }
          });
          this.productData = res.data
          this.productTotal = res.data.length
          if(e){
            this.channelTitle = e.channelName
          }
          this.productShow = true

          // 使用 reduce 方法对 linkType 进行分组并构建 productId 字符串
          const groupedProductIds = res.data.reduce((acc, item) => {
            const { linkType, productId } = item
            // 如果 acc 中还没有这个 linkType 的键，则初始化为空字符串
            if (!acc[linkType]) {
              acc[linkType] = ''
            }
            // 将当前 productId 添加到对应 linkType 的字符串中
            // 如果不是第一个 productId，前面加上逗号
            if (acc[linkType].length > 0) {
              acc[linkType] += `,${productId}`
            } else {
              acc[linkType] = `${productId}`
            }

            return acc
          }, {})

          this.groupedProductIds = groupedProductIds
        }
      })
    },

    // 删除关联商品
    onProductDel(e){
      function removeValueFromStr(str, valueToRemove) {
        // 将字符串分割成数组
        const valuesArray = str.split(',')
        // 过滤掉需要移除的值
        const filteredArray = valuesArray.filter(value => value != valueToRemove)
        // 将过滤后的数组重新组合成字符串
        return filteredArray.join(',')
      }
      
      let str = this.groupedProductIds[e.linkType]
      str = removeValueFromStr(str, e.productId)
      this.loading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/login/edit',
        params: {
          id: this.channelId,
          provideProduct: e.linkType == 1? str : undefined,
          relatedProduct: e.linkType == 2? str : undefined,
          relatedCoupon: e.linkType == 3? str : undefined,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.OpenChannelProductModal();
        }
      })
    },

    // 关联课程快捷搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    onChannelProductAdd(){
      if(!this.productId){
        return this.$message.warning('请选择关联商品！');
      }
      function funcheckAndAlertValue(str, value) {
        let check = false
        // 将字符串分割成数组
        const valuesArray = str.split(',');
        // 检查数组中是否包含指定的值
        if (valuesArray.includes(value)) {
          check = true
        }
        return check
      }
      let str = this.groupedProductIds[this.radioType]
      if(str && funcheckAndAlertValue(str, (this.productId.toString()))){
        return this.$message.warning('当前商品已关联！');
      }

      this.loading = true
      if(this.groupedProductIds[this.radioType]){
        str = this.groupedProductIds[this.radioType] + "," + this.productId
      } else {
        str = this.productId
      }
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/login/edit',
        params: {
          id: this.channelId,
          provideProduct: this.radioType == 1 ? str : undefined,
          relatedProduct: this.radioType == 2 ? str : undefined,
          relatedCoupon: this.radioType == 3 ? str : undefined,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.OpenChannelProductModal();
          this.productAddVisible = false
          this.productId = undefined
        }
      })
    },

    // 查询商品课程
    getProductCourse(){
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select-win",
        params: {
          noExam:1,
          page: 1,
          size: 1000,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询优惠券列表
    getCouponList(){
      this.$ajax({
        url: "/hxclass-management/new-coupon/product/name",
        params: {},
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponData = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 生成二维码
    copyQRCode(e) {
      this.$message.loading({ content: "正在下载...", key, duration: 0 });
      if(this.QRCodemodelLoading){
        this.$message.loading({ content: "下载中，请稍后重试...", key, duration: 0 });
        return
      }
      this.QRCodemodelLoading = true;
      this.$ajax({
        url: '/hxclass-management/channel/getChanaelQRCode',
        method: 'POST',
        params: {
          productName: e.productName,
          url: e.channelLinkURL
        }
      }).then(async res=>{
        var base64Image = "data:image/png;base64," + res;
        downloadBase64Image(base64Image,this.channelTitle)
        this.QRCodemodelLoading = false;
        this.$message.success({ content: "下载成功!", key, duration: 1 });
      })
    },
    
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getProductCourse();
    this.getCouponList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    radioType(){
      this.productId = undefined
    }
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}

.content {
  margin: 10px auto;
  .add-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .list {
    display: flex;
    margin: 10px 0;
    padding-right: 30px;
    align-items: center;
    span {
      display: block;
      flex-shrink: 0;
      width: 95px;
      margin-right: 5px;
      text-align: right;
      &::before{
        content: "*";
        color: red;
      }
    }
    .input {
      flex: 1;
    }
  }
}
</style>
